import React from 'react'

const Home = () => {
  return (
    <div className='home'>
      <div className='home--cont'>
        <img src="/assets/cont.gif" alt='cont' />
        <h3>AUTOMATION + INTERACTIVE + BRANDING <br/> + AI + PROCESSES. Its what we <br />love to do. so relax, we've got this.</h3>
      </div>

      <div className='home--img'>
        <img src="/assets/main.webp" alt="g"/>
      </div>
    </div>
  )
}

export default Home