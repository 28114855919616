import React from 'react'
import "./work.css"

const Work = () => {
  return (
    <div className='work'>
      <h2 className='acc_trigger'>
        <a href="/">
          01&nbsp;
          <strong>WEB</strong>
        </a>
      </h2>
      <div className='acc_container'></div>
      {/* end .acc_container  */}
      <h2 className='acc_trigger'>
      <a href="/">
          02&nbsp;
          <strong>DATA ANALYTICS</strong>
        </a>
      </h2>
      <div className='acc_container'></div>
      {/* end .acc_container  */}
      <h2 className='acc_trigger'>
      <a href="/">
          03&nbsp;
          <strong>SMART CONTRACTS</strong>
        </a>
      </h2>
      <div className='acc_container'></div>
      {/* end .acc_container  */}
      <h2 className='acc_trigger'>
      <a href="/">
          04&nbsp;
          <strong>IDENTITY</strong>
        </a>
      </h2>
      <div className='acc_container'></div>
      {/* end .acc_container  */}
      <h2 className='acc_trigger'>
      <a href="/">
          05&nbsp;
          <strong>OTHER</strong>
        </a>
      </h2>
      <div className='acc_container'></div>
      {/* end .acc_container  */}
        
    </div>
  )
}

export default Work