import React, { useState } from 'react';
import './toppanel.css';

const TopPanel = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div id="toppanel">
      <div id="panel" className={isOpen ? 'open' : 'closed'}>
        <div className="content clearfix">
          <div className="formLabel">
            <h3>CLIENT LOGIN</h3>
          </div>
          <div className="left">
            <form className="clearfix" action="#" method="post">
              <label className="grey" htmlFor="log">Username:</label>
              <input className="field" type="text" name="log" id="log" value="" size="10" />
            </form>
          </div>
          
          <div className="left">			
            <label className="grey" htmlFor="pwd">Password:</label>
            <input className="field" type="password" name="pwd" id="pwd" size="10" />
          </div>
          
          <div className="left">
            <input type="hidden" name="submitID" value="1" />
            <input type="submit" name="submit" value="" className="bt_login" />
          </div>		
          
          <br className="clearfloat" />
          <div className="paneltxt">
            Not a client? Interested in working together? <a href="contact">Click here to request a quote for your next project!</a>
          </div>
        </div>
      </div>
    
      <div className="tab">
        <ul className="login">
          <li className="left">&nbsp;</li>
          <li id="toggle">
            <a id="open" className="open" href="/" onClick={togglePanel} style={{ display: isOpen ? 'none' : 'block' }}>
              Client Login
            </a>
            <a id="close" className="close" href="/" onClick={togglePanel} style={{ display: isOpen ? 'block' : 'none' }}>
              Close Panel
            </a>			
          </li>
          <li className="right">&nbsp;</li>
        </ul> 
      </div>
    </div>
  );
};

export default TopPanel;
