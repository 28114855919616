import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './navbar.css';

const Navbar = () => {
  const [activeLink, setActiveLink] = useState('/');
  const navigate = useNavigate();

  const handleLinkClick = (event, link) => {
    event.preventDefault();  // Prevent default anchor behavior
    setActiveLink(link);
    navigate(link);  // Use navigate to programmatically navigate
  };

  const getLinkImage = (link, defaultImage, activeImage) => {
    return activeLink === link ? activeImage : defaultImage;
  };

  return (
    <div className='nav'>
      <div className='nav--logo'>
        <button id="logo"><img src='/assets/logo.ico' alt="zitran" style={{ width: '150px'}}/></button>
      </div>
      <div className='nav--links'>
        <ul className='navigation'>
          <li id="link--btn">
            <a href="/" onClick={(e) => handleLinkClick(e, '/')}>
              <img src={getLinkImage('/', '/assets/home_btn.gif', '/assets/home_btn_active.gif')} alt="Home" />
            </a>
          </li>
          <li id="vr"></li>
          <li id="link--btn">
            <a href="/about" onClick={(e) => handleLinkClick(e, '/about')}>
              <img src={getLinkImage('/about', '/assets/about_btn.gif', '/assets/about_btn_active.gif')} alt="About" />
            </a>
          </li>
          <li id="vr"></li>
          <li id="link--btn">
            <a href="/work" onClick={(e) => handleLinkClick(e, '/work')}>
              <img src={getLinkImage('/work', '/assets/work_btn.gif', '/assets/work_btn_active.gif')} alt="Work" />
            </a>
          </li>
          <li id="vr"></li>
          <li id="link--btn">
            <a href="/blog" onClick={(e) => handleLinkClick(e, '/blog')}>
              <img src={getLinkImage('/blog', '/assets/blog_btn.gif', '/assets/blog_btn_active.gif')} alt="Blog" />
            </a>
          </li>
          <li id="vr"></li>
          <li id="link--btn">
            <a href="/contact" onClick={(e) => handleLinkClick(e, '/contact')}>
              <img src={getLinkImage('/contact', '/assets/contact_btn.gif', '/assets/contact_btn_active.gif')} alt="Contact" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
