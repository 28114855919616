import React from 'react'

const Blog = () => {
  return (
    <div>
        <main>
            <h2>Welcome back User</h2>
            <left>
                youtube
                learn
                challenges
            </left>
            <right>
                community
                events
            </right>
        </main>
    </div>
  )
}

export default Blog