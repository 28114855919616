import React from 'react'
import "./contact.css"

const Contact = () => {
  return (
    <div className='contact'>
      
      

      <div className='nope'>
        <div className='liner'>
          <div id="chop">
            <img src="/assets/sayHellotxt.gif" alt='hello'/>
          </div>

          <div id="chap">
            <h4>Comments? Suggestions? Interesting project?</h4>
            Please drop us a line using our email form or just stay
            <br/>
            in the loop by joining our mailing list.

            <div id="contactFormBox">
              <form method="post" id="theform">
                <p>
                  <label for="name">Name</label>
                  <br/>
                  <input id="name" type="text" name="name" />
                </p>
                <p>
                  <label for="email">E-mail</label>
                  <br/>
                  <input id="email" type="text" name="email" />
                </p>
                <p>
                  <label for="message">Message</label>
                  <br/>
                  <textarea id="message" rows="10" cols="50" name="message"></textarea>
                </p>
                <p></p>
                {/* <div id="error">
                  There were errors on the form, please make sure all fields are filled out correctly.
                </div> */}
                <div className='g-recaptcha'>
                  
                </div>
                <button className='submit'><img src="/assets/submit.jpg" alt=''/></button>
                <p></p>
              </form>
            </div>
          </div>
        </div>
      </div>
                <div className='line'></div>
      <div className='topPad'>

         <div id="main">
          <div id="mainLeft">
            <img src="/assets/contacttxt.gif" alt=''/>
          </div>

         <div id="mainRight">
            <div className='columnLeft'>
              <img src="/assets/icon_phone.jpg" alt=''/>
              <div className='txt'>
                tel: +254 708 212 968
              </div>

            </div>
            <div className='columnLeft2'>
              <img src="/assets/icon_email.jpg" alt=''/>
              <div className='txt'>
                <a href="/">hello@savana.ac.ke</a>
              </div>
            </div>
            <div className="columnLeft3">
              <img src="/assets/icon_mail.jpg" alt=''/>
              <div className='txt'>
                105 - 1010 Moi Avenue
                <br/>
                Nairobi
              </div>
            </div>
          </div>
        </div> 
      </div>
    
    </div>
  )
}

export default Contact