import React from 'react'
import "./footer.css"

const Footer = () => {
  return (
    <div className='footer'>
        <div className='footer--img'>
            <img src="/assets/footer.gif" alt=""/>
            <div id="footer--mty"></div>
        </div>
        
        <div className='footer--main'>
            <div className='footer--copy'>
            <span id="footer--1">© 2022. All rights Reserved</span><br/>
            <span id="footer--2">DO SOMETHING GOOD</span>
            </div>

            <div className='footer--nav'>
                <h2>Navigation</h2>
                <ul id="footer--ul">
                    <li>About</li>
                    <li>Work</li>
                    <li>Blog</li>
                    <li>Contact</li>
                </ul>
            </div>

            <div className='footer--social'>
                <h2>Social</h2>
                <ul id="footer--ul">
                    <li>Github</li>
                    <li>Facebook</li>
                    <li>Twitter</li>
                </ul>
            </div>

            <div className='footer--address'>
                <p>105-1010 Nairobi, Kenya, V6G 2R6<br/>p.604.765.8831 e.hello © everyautomate.com</p>
            </div>
        </div>
       
    </div>
  )
}

export default Footer